import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "gatsby";
import App from "../../components/App";
import GlobalContext from "../../context/GlobalContext";
import { API_URL_V1 } from "../../config";
import Analytics from "../../components/Analytics";

const Usage = () => {
  const [usages, setUsages] = useState(null);
  const [analytics, setAnalytics] = useState(null);
  const [plan, setPlan] = useState(null);
  const { session } = useContext(GlobalContext);
  const currentMonthUsage = usages && usages[usages.length - 1]
  const previousMonthUsage = usages && usages[usages.length - 2]

  useEffect(() => {
    if (!session) return;

    axios
      .get(`${API_URL_V1}/usage`, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      })
      .then((res) => setUsages(res.data));

    axios.get(`${API_URL_V1}/analytics`, {
      headers: {
        Authorization: `Bearer ${session.token}`,
      },
    })
    .then((res) => setAnalytics(res.data));

    axios.get(`${API_URL_V1}/billing/plan`, {
      headers: {
        Authorization: `Bearer ${session.token}`,
      },
    })
    .then((res) => setPlan(res.data));
  }, [session]);

  return (
    <App>
      <h4>Usage</h4>
      {plan && plan.id === "free-trial" && (
        <>
          <div>
            Your {plan.name} expires on{" "}
            {new Date(plan.expiresAt).toLocaleDateString()}
          </div>
        </>
      )}
      <div className="container mt-10">
          <div className="card">
            <div className="card-body text-center">
              <div className="card-title">
                <h3 className="d-inline">{currentMonthUsage?.eventsCount || 0}</h3>
                {plan && plan.id === "free-trial" && plan?.eventsCap && (
                  <span className="ml-2"> / {plan?.eventsCap}</span>
                )}
              </div>
              <div>events tracked this month</div>
            </div>
            {previousMonthUsage && <div className="small p-4 text-center">Previous month: {previousMonthUsage.eventsCount}</div>} 
          </div>
          {analytics && analytics.length > 0 && <div className="card mt-6">
            <Analytics data={analytics}/>
          </div>}
      </div>
    </App>
  );
};

export default Usage;

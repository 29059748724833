import React, { useEffect } from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: "Moderation Analytics - Last 7 days",
    }
  },
  interaction: {
    mode: 'index',
    intersect: false,
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const defaultData = {
  labels,
  datasets: [
    {
      key: "hate",
      label: "Hate",
      data: [3, 6, 23, 15, 46, 89, 100],
      backgroundColor: "rgb(255, 99, 132)",
    },
    {
      key: "sexual",
      label: "Sexual",
      data: [3, 6, 23, 15, 46, 89, 100],
      backgroundColor: "rgb(75, 192, 192)",
    },
    {
      key: "violence",
      label: "Violence",
      data: [3, 6, 23, 15, 46, 89, 100],
      backgroundColor: "rgb(53, 162, 235)",
    },
    {
      key: "self-harm",
      label: "Self-harm",
      data: [3, 6, 23, 15, 46, 89, 100],
      backgroundColor: "#CD5888",
    },
    {
      key: "threatening",
      label: "Threatening",
      data: [3, 6, 23, 15, 46, 89, 100],
      backgroundColor: "#F2CD5C",
    },
  ],
};

const Analytics = ({data}) => {
  const s = {...defaultData}
  s.labels = data.map(d => new Date(d.day).toLocaleDateString() === new Date().toLocaleDateString() ? "Today" : new Date(d.day).toLocaleDateString())
  s.datasets = s.datasets.map(dt => ({...dt, data: data.map(d => d[dt.key])}))
  return (
    <Bar options={options} data={s} />
  )
}

export default Analytics